import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Chip, OutlinedInput, Select, TextField, useTheme } from '@mui/material';
import { useState } from 'react';

// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFMultiSelect({ name, children, ...other }) {
  const { control } = useFormContext();
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: false, multiple:true }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}