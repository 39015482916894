// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    userRole: path(ROOTS_DASHBOARD, '/user/user-role'),
    userRoleCreate: (name) => path(ROOTS_DASHBOARD, `/user/user-role/create/${name}`),
    userRoleEdit: (id) => path(ROOTS_DASHBOARD, `/user/user-role/edit/${id}`),
  },
  header_menu: {
    root: path(ROOTS_DASHBOARD, '/header-menu')
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    new: path(ROOTS_DASHBOARD, '/customer/new'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    cards: path(ROOTS_DASHBOARD, '/customer/cards'),
    profile: path(ROOTS_DASHBOARD, '/customer/profile'),
    account: path(ROOTS_DASHBOARD, '/customer/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/customer/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/customer/reece-chung/edit`),
  },
  attribute_type: {
    root: path(ROOTS_DASHBOARD, '/attribute-type'),
    list: (name) => path(ROOTS_DASHBOARD, `/attribute-type/${name}`),
    new: (name) => path(ROOTS_DASHBOARD, `/attribute-type/${name}/new`),
    edit: (name,id) => path(ROOTS_DASHBOARD, `/attribute-type/${name}/edit/${id}`),
  },
  attribute_preset: {
    stylePreset: path(ROOTS_DASHBOARD, '/attribute-preset/style-preset'),
    addStylePreset: path(ROOTS_DASHBOARD, '/attribute-preset/style-preset/new'),
    editStylePreset: (id) => path(ROOTS_DASHBOARD, `/attribute-preset/style-preset/edit/${id}`),
    subStylePreset: path(ROOTS_DASHBOARD, '/attribute-preset/sub-style-preset'),
    addSubStylePreset: path(ROOTS_DASHBOARD, '/attribute-preset/sub-style-preset/new'),
    editSubStylePreset: (id) => path(ROOTS_DASHBOARD, `/attribute-preset/sub-style-preset/edit/${id}`)
  },
  attribute_management: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/product/${name}/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product'),
    list: path(ROOTS_DASHBOARD, '/product/list'),
    new: path(ROOTS_DASHBOARD, '/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/product/${name}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/edit/${id}`),
  },

  sizes: {
    root: path(ROOTS_DASHBOARD, '/sizes'),
    standard_sizes: path(ROOTS_DASHBOARD, '/sizes/standard_sizes'),
    sizewiz: path(ROOTS_DASHBOARD, '/sizes/sizewiz'),
    measure_your_body: path(ROOTS_DASHBOARD, '/sizes/measure_your_body'),
    measure_your_shirt: path(ROOTS_DASHBOARD, '/sizes/measure_your_shirt'),
  },
  product_xml: {
    root: path(ROOTS_DASHBOARD, '/product-xml-profile'),
    list: path(ROOTS_DASHBOARD, '/product-xml-profile/list'),
  },

  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    new: path(ROOTS_DASHBOARD, '/category/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/category/${name}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/category/edit/${id}`),
  },
  sub_category: {
    root: path(ROOTS_DASHBOARD, '/sub-category'),
    list: path(ROOTS_DASHBOARD, '/sub-category/list'),
    new: path(ROOTS_DASHBOARD, '/sub-category/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/sub-category/${name}`),
    edit: (_id) => path(ROOTS_DASHBOARD, `/sub-category/${_id}/edit`),
  },

  collection: {
    root: path(ROOTS_DASHBOARD, '/collection'),
    list: path(ROOTS_DASHBOARD, '/collection/list'),
    new: path(ROOTS_DASHBOARD, '/collection/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/collection/${name}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/collection/edit/${id}`),
  },

  supplier: {
    root: path(ROOTS_DASHBOARD, '/supplier'),
    list: path(ROOTS_DASHBOARD, '/supplier/list'),
    new: path(ROOTS_DASHBOARD, '/supplier/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/supplier/${name}`),
    edit: (_id) => path(ROOTS_DASHBOARD, `/supplier/edit/${_id}`),
  },

  apiConfiguration: {
    root: path(ROOTS_DASHBOARD, '/api-configuration'),
    list: path(ROOTS_DASHBOARD, '/api-configuration/list'),
    new: path(ROOTS_DASHBOARD, '/api-configuration/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/api-configuration/${id}/edit`),
  },

  fabric: {
    root: path(ROOTS_DASHBOARD, '/fabric'),
    list: path(ROOTS_DASHBOARD, '/fabric/list'),
    new: path(ROOTS_DASHBOARD, '/fabric/new'),
    compatibilityprofilelist: path(ROOTS_DASHBOARD, '/fabric/compatibilityprofile/list'),
    newcompatibilityprofile: path(ROOTS_DASHBOARD, '/fabric/compatibilityprofile/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/fabric/${name}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/fabric/edit/${id}`),
    editcompatibilityprofile: (_id) => path(ROOTS_DASHBOARD, `/fabric/compatibilityprofile/${_id}/edit`),
    // deletecompatibilityprofile: (_id) => path(ROOTS_DASHBOARD, `/fabric/compatibilityprofile/${_id}/delete`),
    viewcompatibilityprofile: (name) => path(ROOTS_DASHBOARD, `/fabric/compatibilityprofile/${name}`),
  },
  fabricCategory: {
    root: path(ROOTS_DASHBOARD, '/fabric-category'),
    list: path(ROOTS_DASHBOARD, '/fabric-category/list')
  },
  fabricProperties: {
    root: path(ROOTS_DASHBOARD, '/fabric-properties'),
    list: path(ROOTS_DASHBOARD, '/fabric-properties/list'),
  },
  
  feature: {
    root: path(ROOTS_DASHBOARD, '/feature'),
    new: path(ROOTS_DASHBOARD, '/feature/new'),
  },

  grouping: {
    root: path(ROOTS_DASHBOARD, '/product/group'),
    list: path(ROOTS_DASHBOARD, '/product/group/list'),
    new: path(ROOTS_DASHBOARD, '/product/group/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/product/group/edit/${id}`),
  },

  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    list: path(ROOTS_DASHBOARD, '/order/list'),
    new: path(ROOTS_DASHBOARD, '/order/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/order/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/order/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/order/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/order/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },

  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
