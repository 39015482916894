import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    attributeList: [],
    attributeDetailList: [],
    isModalOpen: false,
  };


  const slice = createSlice({
    name: 'attribute',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    
      getAttributeSuccess(state, action) {
        state.isLoading = false;
        state.attributeList = action.payload;
      },

      getAttributeDetailSuccess(state, action) {
        state.isLoading = false;
        state.attributeDetailList = action.payload;
      },
  

      addAttributeSuccess(state, action) {
        const newAttribute = action.payload; 
        state.isLoading = false;
      },

      openModal(state) {
        state.isModalOpen = true;
      },
  
      // CLOSE MODAL
      closeModal(state) {
        state.isModalOpen = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  
  export function getAttributeDetail() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/attributesType');

        dispatch(slice.actions.getAttributeDetailSuccess(response.data));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addAttributeDetail(newAttributeType) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newAttributeType);
        const response = await axios.post('/api/attributesType/add', newAttributeType);
        console.log(response);
        dispatch(slice.actions.addAttributeDetail(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateAttributeDetail(id,newAttributeType) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("update",newAttributeType);
        const response = await axios.put(`/api/attributesType/update/${id}`, newAttributeType);
        console.log(response);
        dispatch(slice.actions.addAttributeDetail(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function deleteAttributeDetail(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("delete");
        const response = await axios.put(`/api/attributesType/delete/${id}`);
        console.log(response);
        dispatch(slice.actions.addAttributeDetail(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function addAttribute(newAttribute) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newAttribute);
        const response = await axios.post('/api/attributesMan/add', newAttribute);
        console.log(response);
        dispatch(slice.actions.addAttributeSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateAttribute(id,newAttribute) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("update",newAttribute);
        const response = await axios.put(`/api/attributesMan/update/${id}`, newAttribute);
        console.log(response);
        dispatch(slice.actions.addAttributeSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function deleteAttribute(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("delete",id);
        const response = await axios.put(`/api/attributesMan/delete/${id}`);
        console.log(response);
        dispatch(slice.actions.addAttributeSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function getAttribute() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/attributesMan');
        console.log(response);
        // const response = {
        //   data: [
        //     {_id: 1, name: 'Collar', image: 'image', description: 'collar', category: 'Shirt', createdDate: '24-05-2024', createdBy: 'Yahya'},
        //     {_id: 2, name: 'WaistBand', image: 'image', description: 'collar', category: 'Shirt', createdDate: '24-05-2024', createdBy: 'Yahya'}
        //   ]
        // };

        dispatch(slice.actions.getAttributeSuccess(response.data));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  // Actions
  export const {openModal,closeModal} = slice.actions;

  
  // ----------------------------------------------------------------------
  



  


 
  