import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    apiConfigurationList: [],


  };


  const slice = createSlice({
    name: 'apiConfiguration',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    
      getApiConfigurationSuccess(state, action) {
        state.isLoading = false;
        state.apiConfigurationList = action.payload;
      },
  

      addApiConfigurationSuccess(state, action) {
        const newApiConfiguration = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getApiConfiguration() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/apiConfig');
        dispatch(slice.actions.getApiConfigurationSuccess(response.data));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addApiConfiguration(newApiConfiguration) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newApiConfiguration);
        const response = await axios.post('/api/apiConfig/add', newApiConfiguration);
        dispatch(slice.actions.addApiConfigurationSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateApiConfiguration(id,newApiConfiguration) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/apiConfig/update/${id}`, newApiConfiguration);
        console.log('API response:', response.data);  // Debugging API response
        dispatch(slice.actions.addApiConfigurationSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: error.message } };
      }
    };
  }

  // export function deleteFabricCompatibilityProfile(id) {
  //   return async (dispatch) => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       console.log("delete");
  //       const response = await axios.delete(`/api/fabricComPro/delete/${id}`);
  //       console.log(response);
  //       dispatch(slice.actions.addFabricCompatibilityProfile(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       console.log('error')
  //       dispatch(slice.actions.hasError(error));
  //       return { payload: { error: 'Error' } };
  //     }
  //   };
  // }



  // export function updateUser(ID, updateUser) {
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.post('/api/user/update', { ...updateUser, ID});
  //        console.log(response,'userresponse')
  //       dispatch(slice.actions.updateUserSuccess(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       if (error.message === 'Email already in use') {
  //         // Handle the specific error
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Email already in use');
  //         return { payload: { error: 'Email already in use' } };
  //       } 
  //         // Handle other errors
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Generic error');
  //         return { payload: { error: 'Email already in use' } };
        
  //     }
      
  //   };
  // }



  


 
  