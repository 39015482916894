import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Box, IconButton, Grid } from '@mui/material';
import { styled } from '@mui/system';
import Iconify from '../Iconify';

const MainImage = styled('img')(({ theme }) => ({
  width: '50%',
  height: 'auto',
  maxHeight: '400px',
  float: 'left'
}));

const ThumbnailImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  maxHeight: '100px',
  float: 'left'
}));

const RHFImagesUploader = () => {
  const [images, setImages] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    const newImages = acceptedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    setImages((prevImages) => [...prevImages, ...newImages]);
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedImages = Array.from(images);
    const [movedImage] = updatedImages.splice(result.source.index, 1);
    updatedImages.splice(result.destination.index, 0, movedImage);

    setImages(updatedImages);
  };

  const removeImage = (index) => {
    setImages((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  return (
    <Box>
      <Box {...getRootProps()} sx={{ border: '2px dashed #ccc', p: 2, textAlign: 'center', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <p>Drag & drop some images here, or click to select images</p>
      </Box>
      {images.length > 0 && (
        <>
          <Box sx={{ mt: 2, position: 'relative' }}>
            <MainImage src={images[0]} alt="main" />
            <IconButton
              size="small"
              sx={{ position: 'absolute', top: 4, right: 4 }}
              onClick={() => removeImage(0)}
            >
              <Iconify icon={"eva:trash-2-fill"} />
            </IconButton>
          </Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="images" direction="horizontal">
              {(provided) => (
                <Grid container spacing={2} sx={{ mt: 2 }} ref={provided.innerRef} {...provided.droppableProps}>
                  {images.slice(1).map((image, index) => (
                    <Draggable key={index + 1} draggableId={`${index + 1}`} index={index + 1}>
                      {(provided, snapshot) => (
                        <Grid
                          item
                          xs={3}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          sx={{
                            opacity: snapshot.isDragging ? 0.5 : 1,
                            cursor: 'move',
                            position: 'relative',
                          }}
                        >
                          <ThumbnailImage src={image} alt={`uploaded ${index + 1}`} />
                          <IconButton
                            size="small"
                            sx={{ position: 'absolute', top: 4, right: 4 }}
                            onClick={() => removeImage(index + 1)}
                          >
                            <Iconify icon={"eva:trash-2-fill"} />
                          </IconButton>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </Box>
  );
};

export default RHFImagesUploader;
