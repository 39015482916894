import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
  const initialState = {
    isLoading: false,
    error: null,
    productXmlList: [],
  };

  const slice = createSlice({
    name: 'productXml',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
    
      getProductXmlSuccess(state, action) {
        state.isLoading = false;
        state.productXmlList = action.payload;
      },
  
      addProductXmlSuccess(state, action) {
        const data = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getProductXml() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/productXmlProfile/');
        console.log("fabric response",response.data);
        dispatch(slice.actions.getProductXmlSuccess(response.data));
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  };

  export function addProductXml(data) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.post('/api/productXmlProfile/add', data);
        dispatch(slice.actions.addProductXmlSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateProductXml(id,data) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.put(`/api/productXmlProfile/update/${id}`, data);
        dispatch(slice.actions.addProductXmlSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }
  



  


 
  