import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import categoryReducer from './slices/category';
import fabricReducer from './slices/fabric';
import orderReducer from './slices/order';
import usersReducer from './slices/users';
import attributeReducer from './slices/attribute';
import attributePresetReducer from './slices/attributePreset';
import fabricCategoryReducer from './slices/fabricCategory';
import fabricCompatibilityProfileReducer from './slices/fabricCompatibilityProfile';
import fabricPropertiesReducer from './slices/fabricProperties';
import collectionReducer from './slices/collection';
import sizeWizReducer from './slices/sizeWiz';
import supplierReducer from './slices/supplier';
import apiConfigurationReducer from './slices/apiConfiguration';
import productXmlReducer from './slices/productXml';
import productGroupingReducer from './slices/productGrouping';
import headerConfigReducer from './slices/headerConfig';


// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  users: usersReducer,
  order: orderReducer,
  fabric: fabricReducer,
  fabricCategory: fabricCategoryReducer,
  category: categoryReducer,
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  attribute: attributeReducer,
  attributePreset: attributePresetReducer,
  fabricCompatibilityProfile:fabricCompatibilityProfileReducer,
  fabricProperties: fabricPropertiesReducer,
  collection: collectionReducer,
  productXml: productXmlReducer,
  productGrouping: productGroupingReducer,
  sizeWiz: sizeWizReducer,
  supplier: supplierReducer,
  apiConfiguration: apiConfigurationReducer,
  headerConfig: headerConfigReducer
});

export { rootPersistConfig, rootReducer };
