import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: "100%", height: 40, ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg width="100%" height="100%" viewBox="0 512 512" xmlns="http://www.w3.org/2000/svg" >
              <defs>
                <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
                  <stop offset="0%" stopColor={PRIMARY_DARK} />
                  <stop offset="100%" stopColor={PRIMARY_MAIN} />
                </linearGradient>
                <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
                  <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                  <stop offset="100%" stopColor={PRIMARY_MAIN} />
                </linearGradient>
                <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
                  <stop offset="0%" stopColor={PRIMARY_LIGHT} />
                  <stop offset="100%" stopColor={PRIMARY_MAIN} />
                </linearGradient>
              </defs>
              <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
                  <path id="Path_801" data-name="Path 801" d="M159.88,17.44A5.44,5.44,0,1,1,154.44,12,5.441,5.441,0,0,1,159.88,17.44Z" transform="translate(13.12 1.057)" />
                  <path id="Path_802" data-name="Path 802" d="M16.61.958H12.441v14.6L3.876.958H0V23.411H4.136V8.649L12.8,23.411H16.61Z" transform="translate(0 0.084)" />
                  <path id="Path_803" data-name="Path 803" d="M34.335,15.194a7.5,7.5,0,0,0-7.719-7.886,7.613,7.613,0,0,0-7.882,7.886,7.713,7.713,0,0,0,7.882,7.984c2.8,0,5.7-1.075,7.1-3.682-1.01-.521-2.084-1.108-3.029-1.629a4.315,4.315,0,0,1-3.811,1.955c-2.247,0-3.941-1.206-4.2-3.259H34.27C34.3,16.2,34.335,15.585,34.335,15.194ZM22.643,13.858a3.724,3.724,0,0,1,4.006-3.226,3.617,3.617,0,0,1,3.811,3.226Z" transform="translate(1.65 0.643)" />
                  <path id="Path_804" data-name="Path 804" d="M39.963,14.549l-5.993,8.277H38.6l3.615-5.084,3.583,5.084h4.625l-5.96-8.277,4.983-6.941H44.784l-2.54,3.748L39.638,7.607H34.98Z" transform="translate(2.991 0.67)" />
                  <path id="Path_805" data-name="Path 805" d="M56.71,2.276H52.769v5.8H50V11.27h2.768V23.3H56.71V11.27h3.224V8.077H56.71Z" transform="translate(4.403 0.2)" />
                  <path id="Path_806" data-name="Path 806" d="M66.46,22.826h3.517L76.393,7.607H72.127L68.219,17.742,64.31,7.607H60.076Z" transform="translate(5.29 0.67)" />
                  <path id="Path_807" data-name="Path 807" d="M81.354,8.277H77.413V23.5h3.941Zm-4.527-5.9A2.464,2.464,0,0,0,79.4,4.79a2.408,2.408,0,0,0,2.508-2.412A2.422,2.422,0,0,0,79.4,0,2.48,2.48,0,0,0,76.826,2.379Z" transform="translate(6.765)" />
                  <path id="Path_808" data-name="Path 808" d="M90.963,17.019c1.14.261,1.694.652,1.661,1.434,0,.88-.847,1.5-1.987,1.532a2.519,2.519,0,0,1-2.8-1.923H84.124c.1,3.259,3.127,5.116,6.123,5.116,3.159,0,6.221-1.564,6.221-4.693,0-1.238-.456-3.585-3.973-4.562l-2.54-.619c-.879-.228-1.6-.652-1.6-1.271,0-1.141.912-1.5,1.922-1.5A1.847,1.847,0,0,1,92.331,12.1h3.778c-.163-3.487-3.029-4.79-5.928-4.79-3,0-5.6,2.053-5.6,4.53,0,1.76.358,3.617,4.3,4.66Z" transform="translate(7.407 0.643)" />
                  <path id="Path_809" data-name="Path 809" d="M102.693,8.277H98.752V23.5h3.941Zm-4.527-5.9a2.464,2.464,0,0,0,2.573,2.412,2.409,2.409,0,0,0,2.508-2.412A2.422,2.422,0,0,0,100.739,0,2.48,2.48,0,0,0,98.166,2.379Z" transform="translate(8.644)" />
                  <path id="Path_810" data-name="Path 810" d="M110.49,15.838a3.97,3.97,0,0,1,4.039-4.106,3.929,3.929,0,0,1,4.006,4.106,4.025,4.025,0,1,1-8.045,0Zm-.065,7.658V21.443a5.317,5.317,0,0,0,4.755,2.379c4.006,0,7.459-3.1,7.459-7.919,0-4.856-3.452-7.951-7.459-7.951a5.223,5.223,0,0,0-4.755,2.346V0h-3.974V23.5Z" transform="translate(9.373)" />
                  <path id="Path_811" data-name="Path 811" d="M124.6,23.5h3.974V0H124.6Z" transform="translate(10.971)" />
                  <path id="Path_812" data-name="Path 812" d="M147.112,15.194a7.5,7.5,0,0,0-7.719-7.886,7.613,7.613,0,0,0-7.882,7.886,7.713,7.713,0,0,0,7.882,7.984c2.8,0,5.7-1.075,7.1-3.682-1.01-.521-2.084-1.108-3.028-1.629a4.317,4.317,0,0,1-3.811,1.955c-2.247,0-3.941-1.206-4.2-3.259h11.594C147.08,16.2,147.112,15.585,147.112,15.194ZM135.42,13.858a3.724,3.724,0,0,1,4.006-3.226,3.617,3.617,0,0,1,3.81,3.226Z" transform="translate(11.58 0.643)" />
                  
              </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
