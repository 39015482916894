import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
    isLoading: false,
    error: null,
    fabricCompatibilityProfileList: [],


  };


  const slice = createSlice({
    name: 'fabricCompatibilityProfile',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },
  
    
      getFabricCompatibilityProfileSuccess(state, action) {
        state.isLoading = false;
        state.fabricCompatibilityProfileList = action.payload;
      },
  

      addFabricCompatibilityProfileSuccess(state, action) {
        const newFabricCompatibilityProfile = action.payload; 
        state.isLoading = false;
      },

    },
  });
  
  // Reducer
  export default slice.reducer;
  

  
  // ----------------------------------------------------------------------
  

  export function getFabricCompatibilityProfile() {
    return async () => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await axios.get('/api/fabricComPro');
        // const response = {
        //   data: [
        //     {_id: 1, name: 'emmad', description: 'A luxurious', status: 'Active', dateAdded : "30.05.2024"},
        //     {_id: 2, name: 'yahya', description: 'A luxurious', status: 'Inactive', dateAdded : "30.05.2024",}
        //   ]
        // };
        dispatch(slice.actions.getFabricCompatibilityProfileSuccess(response.data));
        console.log(response.data,'response');
      } catch (error) {
        dispatch(slice.actions.hasError(error));
      }
    };
  }

  export function addFabricCompatibilityProfile(newFabricCompatibilityProfile) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("new",newFabricCompatibilityProfile);
        const response = await axios.post('/api/fabricComPro/add', newFabricCompatibilityProfile);
        dispatch(slice.actions.addFabricCompatibilityProfileSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function updateFabricCompatibilityProfile(id,newFabricCompatibilityProfile) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("update",updateFabricCompatibilityProfile);
        const response = await axios.put(`/api/fabricComPro/update/${id}`, newFabricCompatibilityProfile);
        dispatch(slice.actions.addFabricCompatibilityProfileSuccess(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }

  export function deleteFabricCompatibilityProfile(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        console.log("delete");
        const response = await axios.delete(`/api/fabricComPro/delete/${id}`);
        console.log(response);
        dispatch(slice.actions.addFabricCompatibilityProfile(response.data.event));
        return { payload: {} };
      } catch (error) {
        console.log('error')
        dispatch(slice.actions.hasError(error));
        return { payload: { error: 'Error' } };
      }
    };
  }



  // export function updateUser(ID, updateUser) {
  //   return async () => {
  //     dispatch(slice.actions.startLoading());
  //     try {
  //       const response = await axios.post('/api/user/update', { ...updateUser, ID});
  //        console.log(response,'userresponse')
  //       dispatch(slice.actions.updateUserSuccess(response.data.event));
  //       return { payload: {} };
  //     } catch (error) {
  //       if (error.message === 'Email already in use') {
  //         // Handle the specific error
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Email already in use');
  //         return { payload: { error: 'Email already in use' } };
  //       } 
  //         // Handle other errors
  //         dispatch(slice.actions.hasError(error));
  //         console.log('Generic error');
  //         return { payload: { error: 'Email already in use' } };
        
  //     }
      
  //   };
  // }



  


 
  